<template>
  <v-dialog v-model="drawer" max-width="600">
    <v-card>
      <v-card-title class="my__v-card-title">
        <div>
          <div>ประวัติการตรวจสอบ</div>
          <div class="text-caption"
               style="margin-top: -6px;">
            รหัสชิ้นส่วน: {{ sectorId + sectorNo + '-' + componentId + componentNo }}
          </div>
        </div>
        <v-icon @click="drawer=false">
          mdi-close
        </v-icon>
      </v-card-title>
      <v-card-text class="my__v-card-text">
        <div class="px-3 pb-3">
          <damage-item
            v-for="(damage, index) in principalHistory.items" :key="`${index}_${damage.id}`"
            :critical="damage.hasCritical"
            :date="dateToThai(stringToDate(String(damage.dateUpdated).slice(0, 10)))"
            :name="damage.type.name"
            :score="damage.score.score || damage.score.condition"
            :src="damage.pathThumbnail"
            class="mt-3 pointer-hover light-blue-hover"
            style="background-color: #f1f1f1;"/>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import DamageItem from '@/components/inspection/InspectionDialog/DamageItem'
import { mapState } from 'vuex'
import utils from '@/assets/js/utils'

export default {
  name: 'HistoryDialog',
  components: { DamageItem },
  data: () => ({
    stringToDate: utils.stringToDate,
    dateToThai: utils.dateToThai
  }),
  computed: {
    ...mapState('inspectionDamageDialog', { sectorId: 'sectorId', sectorNo: 'sectorNo', componentId: 'componentId', componentNo: 'componentNo' }),
    ...mapState('inspectionDamageDialog', { principalHistory: 'principalHistory' }),
    drawer: {
      get () {
        return this.$store.state.inspectionDamageDialog.openHistoryDialog
      },
      set (to) {
        this.$store.commit('inspectionDamageDialog/SET_HISTORY_DIALOG_DRAWER', to)
      }
    }
  }
}
</script>

<style scoped>

</style>
